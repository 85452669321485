<template>
  <section
    v-if="order"
    id="main"
    class="position-absolute w-100"
    style="padding: 22mm; border: 1px dashed black"
  >
    <table>
      <tr>
        <th
          class="packing-slip px-1 pt-0"
          style="color: #d89700; font-size: 2.8rem"
        >
          PACKING SLIP
        </th>
        <th class="text-right"># {{ order.order_number }}</th>
      </tr>
      <tr>
        <td v-if="order.pick_up_point_address">
          <p>
            <b>
              [Pick-up to]:<br />
              {{ order.pick_up_point_name }}<br />
              {{ order.pick_up_point_address }}
            </b>
          </p>
        </td>
        <td v-else>
          <p>
            <b>[Ship to]: {{ order.shipping_address.name }}</b
            ><br />
            {{ order.shipping_address.address }}<br />
            {{ order.shipping_address.city }},
            {{ order.shipping_address.districts }},<br />
            {{ order.shipping_address.postal_code }}<br />
            +62{{ order.shipping_address.phone }}
          </p>
        </td>
        <td class="align-top" align="right">
          <b>Service</b><br />
          {{
            order.pick_up_point_address
              ? "Pickup Point Service"
              : order.courier_name + " - " + order.courier_service_code
          }}
          <table class="p-0 text-right" style="width: fit-content">
            <tr>
              <td>Order Date :</td>
              <td>
                {{ order.date }}
              </td>
            </tr>
            <tr>
              <td>Shipping :</td>
              <td>
                Rp{{ order.shipping.toLocaleString("in") }}
              </td>
            </tr>
          </table>
        </td>
      </tr>
      <tr v-if="order.pick_up_point_platform_name && order.pick_up_point_platform_name != 'OWN'">
        <td colspan="2">
          <div class="border border-danger text-danger bold p-1 d-inline-block mb-1 text-uppercase">
            <font-awesome icon="exclamation-circle" /> Mohon dikirim saat jam operasional outlet (Pk 09.00 - 15.00)
          </div>
        </td>
      </tr>
      <tr v-else-if="jneBarcode">
        <th colspan="2">
          <div class="d-flex align-items-center">
            <img :src="jneBarcode" @load="imgReady++">
            <p class="mb-0 ml-auto">
              {{ order.courier_service_code }} - {{ order.code_destination }}
            </p>
          </div>
        </th>
      </tr>
    </table>
    <table>
      <thead>
        <tr
          class="packing-slip-tb-head"
          style="background-color: #d89700; color: white"
        >
          <th class="w-100">Product</th>
          <th class="text-center" style="padding-right: 2rem">Quantity</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, i) in order.items" :key="i">
          <td>
            <b>{{ item.name }}</b>
          </td>
          <td class="text-center" style="padding-right: 2rem">
            {{ item.quantity }}
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex align-items-center" v-if="order.gift_page_url" style="
      position: absolute;
      bottom: 0;
      left: 22mm;"
    >
      <img
        :src="order.gift_page_url"
        @load="imgReady++"
        style="
          width: 20mm;
          height: 20mm;
          border: 1px dashed black;
        "
      />
      <div class="p-2 text-center">
        <div class="p-2 rounded text-white" style="background: #A3111B;">
          <p class="m-0" style="font-size: 10pt;"><b>ATTENTION</b></p>
          <p class="m-0" style="font-size: 8pt;">Pesanan ini terdapat voice message, gunting QR code di samping,<br>lalu tempelkan pada kartu yang sudah disediakan Whizliz</p>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import bwipjs from 'bwip-js';

export default {
  name: "PackingSlip",
  data() {
    return {
      imgCount: 0,
      imgReady: 0,
      jneBarcode: null,
      order: null,
    };
  },
  computed: {
    isStaging() {
      return process.env.VUE_APP_API_URL.includes('staging');
    },
    isLocal() {
      return process.env.VUE_APP_API_URL.includes('localhost');
    },
    merchantData() {
      return this.$store.getters.getMerchantData;
    },
    isSelectedMerchant() { 
      return ((this.isStaging || this.isLocal) ? [2] : [148]).includes(parseInt(this.merchantData.merchant_id));
    },
  },
  mounted() {
    const id = this.$route.params.id;
    document.title = "packing-slip-#" + id;
    this.getOrderDetail(id);
  },
  methods: {
    async generateShippingBarcode() {
      const canvas = document.createElement('canvas');
      bwipjs.toCanvas(canvas, {
        bcid: 'code128',
        text: this.order.tracking_number,
        scale: 2,
        height: 10,
        includetext: true,
        textxalign: 'center',
      })
      this.jneBarcode = canvas.toDataURL("image/png")
      this.imgCount++;
    },
    async getOrderDetail(id) {
      try {
        const res = await this.$api.order.getOrderDetail(id);
        if (res.status === 200) {
          this.order = res.data.data;
          if (this.order.courier_code == "jne" && this.order.tracking_number && this.isSelectedMerchant)
            this.generateShippingBarcode();
          if (this.order.gift_page_url)
            this.imgCount++;
          else
            this.docReady();
        }
      } catch (e) {
        console.error(e);
      }
    },
    docReady() {
      setTimeout(() => {
        this.$nextTick(() => window.print())
      }, 100);
    },
  },
  watch: {
    imgReady(newVal) {
      console.log(newVal, this.imgCount)
      if (newVal == this.imgCount) this.docReady();
    },
  }
};
</script>
<style scoped>
table {
  width: 100%;
}

table tr th,
td {
  padding: 0.5em;
}

table tr th {
  font-size: 2rem;
}

td table tr td {
  padding: 0;
  padding-left: 1em;
}
</style>
